<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <v-divider class="my-6"></v-divider>
        <v-card outlined>
            <PayFunctionArea v-bind:isPay="payfunctionMap[functionId]"></PayFunctionArea>
            <v-row no-gutters>
                <v-col cols="4" sm="3" md="3" lg="2" class="my-auto">
                    <v-subheader>{{ $t('record.data_type.selection') }}</v-subheader>
                </v-col>
                <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                    <v-radio-group
                        v-model="dataType"
                        row
                        mandatory>
                        <v-radio
                            :label="$t('record.data_type.raw')"
                            value="raw"></v-radio>
                        <v-radio
                            :label="$t('record.data_type.b_card')"
                            value="bCard"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="4" sm="3" md="3" lg="2" class="my-auto">
                    <v-subheader>{{ $t('record.date_range') }}</v-subheader>
                </v-col>
                <v-col cols="8" sm="9" md="9" lg="10" class="my-auto">
                    <v-row>
                        <div>
                            <v-btn x-small color="primary" outlined
                                class="mr-2 normal-case mb-1"
                                @click="setStartDateAsCreateDate">
                                {{ $t('report.query_condition.query_day.value.custom.btn.homework_create_date') }}
                            </v-btn>
                            <v-menu
                                v-model="startDatePicker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        max-width="200px"
                                        v-model="computedStartDateFormatted"
                                        solo
                                        :label="$t('record.date_format')"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on">></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dateRangeStart"
                                    :allowed-dates="allowedStartDates"
                                    @input="startDatePicker = false">
                                </v-date-picker>
                            </v-menu>
                        </div>
                        <p class="my-auto mx-10">{{ $t('record.to') }}</p>
                        <div class="text-right">
                            <v-btn x-small color="primary" outlined
                                class="ml-auto normal-case mb-1"
                                @click="setEndDateAsLastPostDate"
                                :disabled="lastPostDate == null">
                                {{ $t('report.query_condition.query_day.value.custom.btn.last_post_date') }}
                            </v-btn>
                            <v-menu
                                v-model="endDatePicker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="computedEndDateFormatted"
                                        solo
                                        :label="$t('record.date_format')"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on">></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="dateRangeEnd"
                                    :allowed-dates="allowedEndDates"
                                    @input="endDatePicker = false">
                                    <v-spacer></v-spacer>
                                </v-date-picker>
                            </v-menu>
                        </div>
                    </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="4" sm="3" md="3" lg="2" class="my-auto">
                    <v-subheader>{{ $t('record.export_target.title') }}</v-subheader>
                </v-col>
                <v-col cols="8" sm="9" md="9" lg="10" class="pa-2">
                    <v-radio-group
                        v-model="targetStudent"
                        row
                        mandatory
                        @change="targetStudentChange">
                        <v-radio
                            :label="$t('record.export_target.all')"
                            value="all"></v-radio>
                        <v-radio
                            :label="$t('record.export_target.certain_student')"
                            value="select"></v-radio>
                    </v-radio-group>
                    <v-select v-if="showStudents" v-model="selectDiary" :items="students"
                        :label="this.$t('report.category.trend.query_condition.student.title')"
                        item-text="student_name" item-value="diary_id" hide-details single-line dense
                        :loading="isLoading"
                        multiple></v-select>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="4" sm="3" md="3" lg="2" class="my-auto">
                    <v-subheader>{{ $t('record.export_sort.title') }}</v-subheader>
                </v-col>
                <v-col cols="4" sm="5" md="5" lg="4" class="py-2 pl-2">
                    <v-radio-group
                        v-model="orderOption"
                        row
                        mandatory>
                        <v-radio
                            :label="$t('record.export_sort.time')"
                            value="time"></v-radio>
                        <v-radio
                            :label="$t('record.export_sort.name')"
                            value="studentName"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-2 pr-2">
                    <v-select
                        v-model="timeOrderBy"
                        :items="timeOrderOptions"
                        item-text="text"
                        item-value="value"></v-select>
                </v-col>
            </v-row>

            <div class="px-4">
                <v-divider></v-divider>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ma-2 normal-case"
                    outlined
                    text
                    @click="exportData"
                    :disabled="loadingDialog">
                    {{ $t('button.export_data') }}
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        <v-dialog
            v-model="loadingDialog"
            hide-overlay
            persistent
            width="300">
            <v-card color="amber darken-2">
                <v-card-text
                    color="white"
                    class="text-center py-3">
                    {{ $t('record.exporting') }}
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0 my-2"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="noDataDialog"
            persistent
            width="400">
            <v-card class="pa-4">
                <v-subheader>
                    {{ $t('record.no_data') }}
                </v-subheader>
                <v-card-text>
                    {{ $t('record.no_data_description') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text class="normal-case"
                        @click="noDataDialog = false"
                        outlined>
                        {{ $t('record.close') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="noDateDialog"
            persistent
            width="400">
            <v-card class="pa-4">
                <v-subheader>
                    {{ $t('record.no_date_dialog.title') }}
                </v-subheader>
                <v-card-text>
                    {{ $t('record.no_date_dialog.description') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text class="normal-case"
                        @click="noDateDialog = false"
                        outlined>
                        {{ $t('record.close') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="responseDialog"
            persistent
            width="400">
            <v-card class="pa-4">
                <v-subheader>
                    {{ $t('record.response_dialog.title') }}
                </v-subheader>
                <v-card-text>
                    {{ responseDialogText }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text class="normal-case"
                        @click="responseDialog = false"
                        outlined>
                        {{ $t('record.close') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

import { exportServices } from "@/services/export.js";
import fileDownload from "js-file-download";
import PayFunctionArea from '@/components/console/diary/component/PayFunctionArea.vue';
import { diaryServices } from "@/services/diary.js";
import { getItem } from '@/utils/storageManager';

import { mapState } from "vuex";
export default {
    name: "export",
    components: { PayFunctionArea },
    data() {
        return {
            functionId: 'f7',
            payfunctionMap: JSON.parse(getItem('payfunctionMap')),
            breadcrumbsItems: [],
            dataType: null,
            targetStudent: '',
            dateRangeStart: '',
            startDatePicker: false,
            endDatePicker: false,
            dateRangeEnd: '',
            orderOption: '',
            timeOrderBy: { text: this.$t('record.sort.desc'), value: 'desc' },
            timeOrderOptions: [
                { text: this.$t('record.sort.desc'), value: 'desc' },
                { text: this.$t('record.sort.asc'), value: 'asc' },
            ],
            loadingDialog: false,
            noDataDialog: false,
            homeworkId: '',
            noDateDialog: false,
            students: [],
            isLoading: false,
            selectDiary: [],
            showStudents: false,
            responseDialogText: '',
            responseDialog: false,
            lastPostDate: null,
            homeworkCreateDate: null,
        }
    },
    async created() {
        this.homeworkId = this.$route.params.id;
        this.breadcrumbsItems.push(
            {
                text: this.$t('index.title'),
                disabled: false,
                href: `/diary`,
            },
            {
                text: this.homeworkName,
                disabled: false,
                href: `/diary/homework/${this.homeworkId}`,
            },
            {
                text: this.$t('menu.diary_data_export'),
                disabled: true,
            }
        );
        if (!this.payfunctionMap[this.functionId]) {
            this.homeworkId = null;
            return;
        }
        this.isLoading = true;
        diaryServices.getHomeworkStudentName(this.homeworkId, {}).then((res) => {
            if (res.success) {
                this.students = res.data;
                this.isLoading = false;
            }
        });
        const diaryHomeworkNameRes = await diaryServices.getHomeworkName(this.homeworkId, {});
        this.homeworkCreateDate = diaryHomeworkNameRes.data.create_date;
        this.lastPostDate = diaryHomeworkNameRes.data.last_post_date;
    },
    computed: {
        ...mapState(["homeworkName"]),
        computedStartDateFormatted() {
            return this.formatDate(this.dateRangeStart);
        },
        computedEndDateFormatted() {
            return this.formatDate(this.dateRangeEnd);
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${year}/${month}/${day}`
        },
        allowedStartDates(dateValue) {
            if (dateValue > new Date().toISOString()) {
                return false;
            }
            if (this.dateRangeEnd == null || this.dateRangeEnd == '') {
                return true;
            } else {
                return dateValue <= this.dateRangeEnd ? true : false;
            }
        },
        allowedEndDates(dateValue) {
            if (dateValue > new Date().toISOString()) {
                return false;
            }
            if (this.dateRangeStart == null || this.dateRangeStart == '') {
                return true;
            } else {
                return dateValue >= this.dateRangeStart ? true : false;
            }
        },
        async exportData() {
            if (!this.dateRangeStart || !this.dateRangeEnd ||
                this.dateRangeEnd < this.dateRangeStart) {
                this.noDateDialog = true;
                return;
            }
            this.loadingDialog = true;
            let payload = {
                homework_id: this.homeworkId,
                start_date: this.dateRangeStart,
                end_date: this.dateRangeEnd,
                order_option: this.orderOption,
                time_desc: this.timeOrderBy == 'desc' ? true : false,
            }

            if (this.targetStudent == 'select') {
                payload['diary_id'] = this.selectDiary;
            }

            let fileName = '';
            let res = null;
            if (this.dataType == 'raw') {
                fileName = this.$t('record.export_file_name.raw') + '.xlsx'
                res = await exportServices.getRawData(payload);
            } else {
                fileName = this.$t('record.export_file_name.bcard') + '.xlsx'
                res = await exportServices.getBCardData(payload);
            }

            if (res.status == 200) {
                try {
                    // Clone the response for a second read attempt
                    const cloneRes = res.clone();
                    // Try to read the response as a blob
                    const blobFile = await res.blob();
                    if (blobFile.type !== 'application/json') {
                        fileDownload(blobFile, fileName);
                        this.loadingDialog = false;
                    } else {
                        // If the blob type is 'application/json', then read the cloned response as JSON
                        const jsonData = await cloneRes.json();
                        // Handle JSON response...
                        if (jsonData.message == "沒有日記內容" || jsonData.message == 'Record not found') {
                            this.loadingDialog = false;
                            this.noDataDialog = true;
                            return;
                        }
                        switch (jsonData.return_code) {
                            case '13000':
                                this.responseDialogText = this.$t('record.response_dialog.message_generating');
                                break;
                            case '13001':
                                this.responseDialogText = this.$t('record.response_dialog.message_generating_not_all');
                                break;
                            case '-13001':
                                this.responseDialogText = this.$t('record.response_dialog.message_no_comment');
                                break;
                        }
                        this.loadingDialog = false;
                        this.responseDialog = true;
                    }
                } catch (error) {
                    console.error('Error processing response:', error);
                    this.loadingDialog = false;
                }
            }
        },
        targetStudentChange() {
            if (this.targetStudent == 'all') {
                this.showStudents = false;
            } else {
                this.showStudents = true;
            }
        },
        setStartDateAsCreateDate() {
            this.dateRangeStart = this.homeworkCreateDate;
        },
        setEndDateAsLastPostDate() {
            this.dateRangeEnd = this.lastPostDate;
        },
    },
    watch: {
        homeworkName() {
            this.breadcrumbsItems[1].text = this.homeworkName;
        },
    },
}
</script>

<style scoped></style>